import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './App.css';
import LandingPage from './components/landingPage';

function App() {
  return (
    <>
      <LandingPage />
    </>
  );
}

export default App;
